const enums = {}

// 优惠券状态
enums.couponStatus = [
  {
    label: '未开始',
    value: 0,
    status: 'info',
  },
  {
    label: '进行中',
    value: 1,
    status: 'success',
  },
  {
    label: '暂停',
    value: 2,
    status: 'warning',
  },
  {
    label: '结束',
    value: 3,
    status: 'info',
  },
]

// 优惠券类型
enums.couponType = [
  {
    value: 1,
    label: '银联优惠券活动',
  },
  {
    value: 3,
    label: '自建活动',
  },
  {
    value: 2,
    label: '广告宣传活动',
  },
]

// 通用状态
enums.commonStatus = [
  {
    value: 1,
    label: '启用',
    type: 'success',
  },
  {
    value: 0,
    label: '禁用',
    type: 'info',
  },
]

export default enums
